window.initMap = function(){

    //Map config
    var center = {lat: 46.71109, lng:1.7191036};
    var zoom = 6;

    if(window.matchMedia("(max-width: 720px)").matches || window.matchMedia("(max-height: 768px)").matches){
        zoom = 5;
    }

    var map = new google.maps.Map(document.getElementById('map'), {
        center: center,
        zoom: zoom,
        minZoom: zoom,
        disableDefaultUI: true, // a way to quickly hide all controls,
        streetViewControl: false,
        scaleControl: true,
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
        },
        styles: [
            {
                "featureType": "administrative.locality",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": "0"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "lightness": "100"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#f3f4f4"
                    },
                    {
                        "saturation": -84
                    },
                    {
                        "lightness": 59
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "labels",
                "stylers": [
                    {
                        "hue": "#ff0000"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": "100"
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape.natural",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.attraction",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.business",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.government",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.medical",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#adff00"
                    },
                    {
                        "saturation": "0"
                    },
                    {
                        "lightness": "-9"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi.place_of_worship",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.school",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#00ffff"
                    },
                    {
                        "saturation": -60
                    },
                    {
                        "lightness": 23
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.sports_complex",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": "0"
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#ff0000"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 100
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                    {
                        "hue": "#ff0000"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 26
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#ffcc00"
                    },
                    {
                        "saturation": 100
                    },
                    {
                        "lightness": "-10"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#ffcc00"
                    },
                    {
                        "saturation": 100
                    },
                    {
                        "lightness": "-15"
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": "0"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#00b5ff"
                    },
                    {
                        "saturation": 55
                    },
                    {
                        "lightness": -6
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels",
                "stylers": [
                    {
                        "hue": "#7fc8ed"
                    },
                    {
                        "saturation": 55
                    },
                    {
                        "lightness": -6
                    },
                    {
                        "visibility": "off"
                    }
                ]
            }
        ]
    });

    //Markers
    var markers = [];

    //Geolocalisation
    var geolocalisationBtn = document.getElementById('geolocalisation');

    if(geolocalisationBtn){
        geolocalisationBtn.addEventListener('click', () => {
            if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition(position => {
                    var pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    map.setCenter(pos);
                    map.setZoom(12);
                })
            }
        });
    }

    //Recherche
    var searchBoxContainer = document.getElementById('search-map');
    var searchBtn = document.getElementById('search-map-btn');
    var options = {
        componentRestrictions: {country: "fr"}
    };

    //Helpers
    function findClosestMarker(event) {
        var distances = [];
        var closest = -1;
        for (i = 0; i < markers.length; i++) {
            var d = google.maps.geometry.spherical.computeDistanceBetween(markers[i].position, event);
            distances[i] = d;
            if (closest == -1 || d < distances[closest]) {
                closest = i;
            }
        }
        return markers[closest].getPosition();
    }

    if(searchBoxContainer){
        var searchBox = new google.maps.places.Autocomplete(searchBoxContainer, options);

        searchBoxContainer.addEventListener('keypress', (e) => {
            if(e.keyCode == '13' && e.target.value.length > 0){
                var place = searchBox.getPlace();
                var bounds = new google.maps.LatLngBounds();
                var nearest = findClosestMarker(place.geometry.location);

                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                bounds.extend(place.geometry.location);
                bounds.extend(nearest);
                map.fitBounds(bounds);
                map.setZoom(map.getZoom() - 3);
            }
        });

        searchBtn.addEventListener('click', () => {
            var place = searchBox.getPlace();
            var bounds = new google.maps.LatLngBounds();

            var nearest = findClosestMarker(place.geometry.location);

            if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
            }

            bounds.extend(place.geometry.location);
            bounds.extend(nearest);
            map.fitBounds(bounds);
            map.setZoom(map.getZoom() - 3);
        });

        map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());
        });
    }

    //Populate markers
    for(var i = 0; i < window.labsMarkers.length; i++){
        var marker = new google.maps.Marker({
            position: {lat: parseFloat(window.labsMarkers[i].address.lat), lng: parseFloat(window.labsMarkers[i].address.lng)},
            map: map,
            icon: '../themes/LBIMainSite/static/img/marker.png',
            title: window.labsMarkers[i].name.replace('&rsquo;', '\'')
        });
        markers.push(marker);

        var content = '';

        var infoWindow = null;
        var prevInfowindow = null;

        var prevMarker = null;

        var offsetCenter = 5;

        if(window.matchMedia("(max-width:720px)").matches){
            content = '<div class="infowindow">'+
                '<i class="icon-close" id="close-info"></i>'+
                '<p class="infowindow__title">'+window.labsMarkers[i].name+'</p>'+
                '<div class="infowindow__address"><i class="icon-place"></i>'+window.labsMarkers[i].address.address+'</div>'+
                '<div class="infowindow__numbers"><div class="infowindow__phone"><i class="icon-phone"></i>'+window.labsMarkers[i].phone+'</div><div class="infowindow__fax"><i class="icon-fax"></i>'+window.labsMarkers[i].fax+'</div></div>'+
                '<div class="infowindow__cta"><div class="infowindow__cta--fiche"><a href="/nos-laboratoires/'+ window.labsMarkers[i].slug +'" class="btn">Voir la fiche</a></div><div class="infowindow__cta--rdv"><a href="" class=""><i class="icon-date"></i>Prendre RDV</a></div></div>'+
                '</div>';
        }else{
            content = '<div class="infowindow">'+
                '<p class="infowindow__title">'+window.labsMarkers[i].name+'</p>'+
                '<div class="infowindow__address"><i class="icon-place"></i>'+window.labsMarkers[i].address.address+'</div>'+
                '<div class="infowindow__numbers"><div class="infowindow__phone"><i class="icon-phone"></i>'+window.labsMarkers[i].phone+'</div><div class="infowindow__fax"><i class="icon-fax"></i>'+window.labsMarkers[i].fax+'</div></div>'+
                '<div class="infowindow__cta"><div class="infowindow__cta--fiche"><a href="/nos-laboratoires/'+ window.labsMarkers[i].slug +'" class="btn">Voir la fiche</a></div><div class="infowindow__cta--rdv"><a href="tel:'+ window.labsMarkers[i].phone +'" class=""><i class="icon-date"></i>Prendre RDV</a></div></div>'+
                '</div>';
        }

        google.maps.event.addListener(marker,'click', (function(marker,content,infoWindow){
            return function() {

                infoWindow = new google.maps.InfoWindow();

                google.maps.event.addListener(infoWindow, 'closeclick', (function(marker,content,infoWindow){
                    return function() {
                        marker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                    };
                })(marker,content,infoWindow));

                if(window.matchMedia("(max-width:720px)").matches){
                    if (prevInfowindow) {
                        document.querySelector('.infowindow--mobile').style.opacity = 0;
                        document.querySelector('.infowindow--mobile').style.visibility = 'hidden';
                        document.querySelector('.infowindow--mobile').innerHTML = '';
                        prevMarker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                    }
                    marker.setIcon('../themes/LBIMainSite/static/img/bigmarker.png');
                    document.querySelector('.infowindow--mobile').style.visibility = 'visible';
                    document.querySelector('.infowindow--mobile').style.opacity = 1;
                    document.querySelector('.infowindow--mobile').innerHTML += content;
                    document.getElementById('close-info').addEventListener('click', () => {
                        document.querySelector('.infowindow--mobile').style.opacity = 0;
                        document.querySelector('.infowindow--mobile').style.visibility = 'hidden';
                        document.querySelector('.infowindow--mobile').innerHTML = '';
                        marker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                    });
                    map.setCenter(marker.getPosition());
                    prevInfowindow = infoWindow;
                    prevMarker = marker;
                }else{
                    if (prevInfowindow) {
                        prevInfowindow.close();
                        prevMarker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                    }
                    marker.setIcon('../themes/LBIMainSite/static/img/bigmarker.png');
                    infoWindow.setContent(content);
                    infoWindow.open(map, marker);
                    map.setCenter({lat: marker.getPosition().lat(), lng: marker.getPosition().lng()});
                    map.panBy(0, -100);
                    prevInfowindow = infoWindow;
                    prevMarker = marker;
                }
            };
        })(marker,content,infoWindow));

        google.maps.event.addListener(map,'click', (function(marker, infoWindow){
            return function() {
                if(window.matchMedia("(max-width:720px)").matches){
                    document.querySelector('.infowindow--mobile').style.opacity = 0;
                    document.querySelector('.infowindow--mobile').innerHTML = '';
                    marker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                }else{
                    if(prevInfowindow){
                        prevInfowindow.close(map,marker);
                    }
                    marker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                }
            };
        })(marker, infoWindow));

    }

    var clusterStyles = [
        {
            textColor: 'white',
            textSize: 18,
            url: '../themes/LBIMainSite/static/img/cluster.png',
            height: 46,
            width: 46
        },
        {
            textColor: 'white',
            textSize: 18,
            url: '../themes/LBIMainSite/static/img/cluster.png',
            height: 46,
            width: 46
        },
        {
            textColor: 'white',
            textSize: 18,
            url: '../themes/LBIMainSite/static/img/cluster.png',
            height: 46,
            width: 46
        }
    ];

    var mcOptions = {
        gridSize: 50,
        styles: clusterStyles,
        maxZoom: 15
    };

    var markerCluster = new MarkerClusterer(map, markers, mcOptions);
};
